import React from "react";
import DrCallis from "../../assets/dr-callis-headshot-big.jpg";
import "./PhilosophyOfPractice.css";

const PhilosophyOfPractice = () => {
  return (
    <section className="subpage philosophy-of-practice">
      <article className="subpage-content">
        <header>
          <h1>PHILOSOPHY OF PRACTICE</h1>
        </header>
        <img src={DrCallis} alt="Doctor Callis" id="dr-callis" />
        <p>
          I believe that educating you about your medical condition and your
          medication is essential to great clinical outcomes. I will work with
          you to help you understand the disease, the process, and the
          medications available for treatment. Our goal is to treat your
          arthritis in the context of your other medical conditions while
          keeping the common goals of lowered disease activity, decreased pain,
          increased quality of life, and improved mental well-being always as
          our focus. I believe close monitoring of your condition and
          medications is an important part of safety. As a rheumatoid patient
          myself, I understand the challenges of the disease, medication
          decisions, lifestyle modifications, and psychological impact that can
          accompany the diagnosis. I hope to share my clinical and personal
          knowledge with every patient as we work to treat your condition.
        </p>
      </article>
    </section>
  );
};

export default PhilosophyOfPractice;
