import React from "react";

const SyringeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 100 100"
      id="syringe-icon"
    >
      <path d="M87.32 13.498c0-.001 0-.001 0 0l-5.646-4.764a1.5 1.5 0 10-1.935 2.293l4.499 3.797-10.526 12.474-11.719-9.889a1.5 1.5 0 00-1.935 2.293l6.073 5.125-29.55 35.017c-.316.375-.43.881-.302 1.355.177.659.4 1.296.662 1.912l-4.934 5.847c-1.945 2.305-1.924 5.621-.114 7.895L19.676 91.331a1.5 1.5 0 002.292 1.935L34.19 78.782a6.22 6.22 0 007.759-1.434l4.947-5.863c.373.087.75.163 1.133.221l.765.116a1.498 1.498 0 001.37-.516l6.139-7.275v-.001L68.737 49.3l.001-.001.001-.001 6.214-7.363.001-.001.001-.002 4.761-5.642 6.073 5.125a1.496 1.496 0 002.113-.179 1.496 1.496 0 00-.179-2.114l-11.718-9.889 10.527-12.475 4.5 3.797a1.496 1.496 0 002.113-.179 1.496 1.496 0 00-.179-2.114l-5.646-4.764zM39.657 75.414a3.21 3.21 0 01-2.195 1.135 3.205 3.205 0 01-2.355-.75l-.421-.355a3.234 3.234 0 01-.385-4.551l4.213-4.992a14.406 14.406 0 005.366 4.509l-4.223 5.004zm33.971-36.562l-2.093-1.767a1.5 1.5 0 10-1.935 2.293l2.093 1.766-4.281 5.073-2.093-1.766a1.5 1.5 0 10-1.935 2.293l2.093 1.766-4.281 5.073-2.093-1.766a1.5 1.5 0 10-1.935 2.293l2.093 1.766-4.281 5.074-2.093-1.767a1.5 1.5 0 00-1.935 2.293l2.093 1.766-4.631 5.488a11.399 11.399 0 01-9.015-7.575L68.341 26.86l8.999 7.594-3.712 4.398z"></path>
    </svg>
  );
};

export default SyringeIcon;
