import React from "react";
import {
  ClipboardIcon,
  SyringeIcon,
  BloodIcon,
  Computer,
} from "../../../assets/icons/index";
import "./HomeServices.css";

const HomeServices = () => {
  return (
    <section className="home-services">
      <div className="services-title">
        <h3>A Comfortable and Friendly Experience</h3>
        <h2 className="">Here's How We Can Help</h2>
      </div>
      <div className="services-tiles">
        <div className="tile-box">
          <div className="services-tile">
            <ClipboardIcon />
            <h3>
              CLINIC
              <br />
              APPOINTMENTS
            </h3>
          </div>
          <div className="tile-inside">
            <span>
              Dr. Callis and her staff are happy to see patients in office. Each
              exam room is equipped with a special Hepa filter for enhanced air
              purity and all exam rooms are thoroughly cleaned between patient
              appointments. While you are here you can check out some of Dr.
              Callis' personalized exam room décor!
            </span>
          </div>
        </div>
        <div className="tile-box">
          <div className="services-tile">
            <SyringeIcon />
            <h3>
              IV INFUSION &
              <br />
              SUB Q INJECTIONS
            </h3>
          </div>
          <div className="tile-inside">
            <span>
              Some medications to treat autoimmune diseases and osteoporosis are
              administered by IV infusion or Injection. At NKY Arthritis, a
              Registered Nurse administers these medications and monitors you
              during your stay. We are happy to administer medication at our
              clinic for other providers and we are the only private infusion
              center in Northern Kentucky with a Medical Doctor on site at all
              times. Please see our Infusion page for more information.
            </span>
          </div>
        </div>
        <div className="tile-box">
          <div className="services-tile">
            <BloodIcon />
            <h3>
              QUEST
              <br />
              DIAGNOSTICS
            </h3>
          </div>
          <div className="tile-inside">
            <span>
              Laboratory analysis is an important component in disease diagnosis
              and medication monitoring. Quest Diagnostics Lab is conveniently
              located in our office to perform blood draws for our patients.
              <br />
              Mon, Tues, Thurs, Fri 8-3:30 pm. Closed 12:30-1 for lunch.
            </span>
          </div>
        </div>
        <div className="tile-box">
          <div className="services-tile">
            <Computer />
            <h3>
              PATIENT
              <br />
              PORTAL
            </h3>
          </div>
          <div className="tile-inside">
            <span>
              NKY Arthritis utilizes a powerful medical record system. You can
              view your notes, lab reports, communicate with the office, update
              your medications, share medical records with us and more! NKY
              Arthritis staff is happy to sign you up for our patient portal!
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeServices;
