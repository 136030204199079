import React from "react";
import DrCallisHeadshot from "../../../assets/dr-callis-headshot-big.jpg";
import ABIMLogo from "../../../assets/abim-logo.jpg";
import "./HomeDrCallis.css";

const HomeDrCallis = () => {
  return (
    <section className="home-dr-callis">
      <img src={DrCallisHeadshot} alt="Doctor Callis" id="dr-callis-headshot" />
      <article>
        <h2>
          <span className="cursive meet">Meet</span>
          <br />
          Dr. Callis
        </h2>
        <p>
          Dr. Rebecca Brinker Callis is originally from Northern Kentucky and
          recently moved back home to the area. She graduated college summa cum
          laude and Phi Beta Kappa from the University of Kentucky. She then
          completed Internal Medicine Residency and Rheumatology Fellowship at
          Washington University in St. Louis. Dr. Callis is a multiple recipient
          of the patient elected "My Doc Rocks" award and believes patient
          education and collaboration fosters the best clinical outcomes.
        </p>
        <img
          src={ABIMLogo}
          alt="American Board of Internal Medicine Certified"
          id="abim-logo"
        />
      </article>
    </section>
  );
};

export default HomeDrCallis;
