import React from "react";

const ResearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="854.557"
      height="577.897"
      data-name="Layer 1"
      viewBox="0 0 854.557 577.897"
      id="research-icon"
    >
      <path fill="#ccc" d="M151.557 268H261.557V391H151.557z"></path>
      <path
        fill="#e6e6e6"
        d="M95.557 576L39.557 576 33.557 528 101.557 528 95.557 576z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M380.729 702.284l-1.89 25.835a8.224 8.224 0 008.7 8.809l13.27-.804a4.195 4.195 0 003.815-5.204l-7.366-29.463zM491.724 717.56c15.618 3.654 26.774 8.847 34.103 15.875a15.035 15.035 0 009.624 4.126c4.596.241 8.355-.274 11.18-1.536a4.43 4.43 0 002.624-3.65 4.508 4.508 0 00-1.888-4.167l-37.04-26.355-15.733 6.294-3.67 3.67a3.634 3.634 0 00.8 5.744z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#2f2e41"
        d="M443.662 295.746l-105.873-.98c.266-15.421 3.854-30.564 11.696-44.114 5.151-8.9 5.04-18.585 3.798-26.745-1.915-12.592 2.54-25.475 12.438-33.49a39.916 39.916 0 0126.842-8.882l2.056.086a40.008 40.008 0 0132.87 19.77 33.327 33.327 0 013.962 20.3c-.671 6.601.935 13.27 4.69 20.002 6.48 14.783 7.884 33.699 7.52 54.053z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#01a7e2"
        d="M514.497 703.228l-20.78 5.42-101.183-220.435 6.324 215.015H378.08c-22.203-101.16-62.29-217.907-44.267-254.766l107.507-6.324z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <circle cx="218.909" cy="69.685" r="28.006" fill="#ffb9b9"></circle>
      <path
        fill="#ffb9b9"
        d="M416.023 282.232l-46.074-2.71c4.515-10.364 6.319-20.344 4.517-29.814h34.33c-.078 7.271 3.54 19.848 7.227 32.524z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#01a7e2"
        d="M272.211 289.217L171.028 296.445 181.417 117.115 199.034 112.146 218.909 116.663 238.784 106.725 251.432 111.243 272.211 289.217z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M360.914 579.458l-69.112-15.81c20.55-68.148 57.574-224.05 45.623-279.61l36.137-16.261z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M327.488 382.512l-26.2-16.262 22.344-65.44a30.871 30.871 0 0113.494-16.028l7.527-4.357-4.518 65.95zM505.01 564.552c-21.813 12.62-76.339 19.655-100.28 10.841-1.761-61.573-5.216-235.739 5.873-309.423l39.75 16.262-3.613 48.785c-19.004 41.733 36.18 187.088 58.27 233.535z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M329.127 213.33L304.734 205.199 272.211 168.158 277.632 121.18 331.837 197.068 329.127 213.33z"
      ></path>
      <path
        fill="#ffb9b9"
        d="M425.403 409.545a6.785 6.785 0 008.105 5.113l20.706-4.69-2.992-13.216-20.707 4.688a6.777 6.777 0 00-5.112 8.105z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#2f2e41"
        d="M416.023 223.509c-15.556-6.396-30.919-6.86-46.074-.903l-6.324-22.586h57.819z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M444.03 394.256l11.744 21.683c19.754-5.51 36.874-22.88 53.302-43.365l-4.517-14.455-28.006 7.228z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#3f3d56"
        d="M435.447 349.537h-3.614c0-51.11-10.523-86.729-20.779-86.729l-.903-3.614c9.197 0 15.06 14.824 18.36 27.26 4.473 16.86 6.936 39.263 6.936 63.083zM352.332 336.889h-3.614c0-46.81 10.596-75.888 26.2-75.888l-.904 3.614c-10.793 0-21.682 22.348-21.682 72.274z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <circle cx="260.918" cy="189.389" r="9.034" fill="#3f3d56"></circle>
      <path
        fill="#3f3d56"
        d="M337.877 358.571h-3.614c0-12.952 7.295-23.489 16.262-23.489v3.614c-6.974 0-12.648 8.916-12.648 19.875z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#3f3d56"
        d="M366.787 358.571h-3.614c0-10.96-5.674-19.875-12.648-19.875v-3.614c8.967 0 16.262 10.537 16.262 23.49z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <circle cx="192.258" cy="198.423" r="3.614" fill="#3f3d56"></circle>
      <circle cx="163.348" cy="198.423" r="3.614" fill="#3f3d56"></circle>
      <path
        fill="#01a7e2"
        d="M118.177 165.193L140.724 169.219 192.258 242.495 164.88 236.053 118.177 165.193z"
      ></path>
      <path
        fill="#3f3d56"
        d="M286.9 325.347a1.918 1.918 0 00-1.574 2.971l48.171 73.088a1.911 1.911 0 001.16.81l22.413 5.274a1.914 1.914 0 002.005-2.965l-52.89-75.202a1.903 1.903 0 00-1.229-.783l-17.695-3.16a2.076 2.076 0 00-.361-.033z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#ffb9b9"
        d="M316.95 377.604l1.338 13.486 21.126-2.098a6.776 6.776 0 00-1.338-13.485z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M298.578 376.042a17.427 17.427 0 0017.407 17.31h3.474l2.529-21.075-20.94-7.538z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path fill="#e6e6e6" d="M442.557 0H854.557V217H442.557z"></path>
      <path
        fill="#fff"
        d="M627.278 366.051h388v-193h-388z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path fill="#3f3d56" d="M484.557 40H622.557V178H484.557z"></path>
      <circle cx="589.557" cy="99" r="4" fill="#01a7e2"></circle>
      <circle cx="574.557" cy="85" r="4" fill="#01a7e2"></circle>
      <path
        fill="#01a7e2"
        d="M745.278 260.051a7.95 7.95 0 00-4.168 1.181 25.124 25.124 0 00-3.864-4.535c.017-.214.032-.428.032-.646a7.994 7.994 0 00-13.546-5.756 24.507 24.507 0 00-6.463-.057c.001-.063.01-.124.01-.187a8 8 0 10-13.26 6.019 25.14 25.14 0 00-3.624 3.833 8 8 0 10-5.117 14.148h.026a25.32 25.32 0 00-.026 1 25.045 25.045 0 00.724 5.973 14.994 14.994 0 1019.569 18.579 24.971 24.971 0 0016.342-2.425 8 8 0 1010.492-10.492 24.857 24.857 0 002.848-10.636l.025.001a8 8 0 000-16z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path fill="#e6e6e6" d="M530.557 37H570.557V43H530.557z"></path>
      <path fill="#3f3d56" d="M660.557 40H798.557V178H660.557z"></path>
      <circle cx="755.757" cy="131.8" r="1.8" fill="#01a7e2"></circle>
      <circle cx="749.007" cy="125.5" r="1.8" fill="#01a7e2"></circle>
      <path
        fill="#01a7e2"
        d="M920.828 292.851a3.577 3.577 0 00-1.875.532 11.306 11.306 0 00-1.74-2.041 3.62 3.62 0 00.015-.29 3.597 3.597 0 00-6.096-2.591 11.028 11.028 0 00-2.908-.026c0-.028.004-.055.004-.084a3.6 3.6 0 10-5.966 2.709 11.313 11.313 0 00-1.631 1.725 3.6 3.6 0 10-2.303 6.366h.012c-.006.15-.012.3-.012.45a11.27 11.27 0 00.326 2.688 6.747 6.747 0 108.806 8.36 11.237 11.237 0 007.354-1.09 3.6 3.6 0 104.721-4.722 11.186 11.186 0 001.282-4.786h.011a3.6 3.6 0 000-7.2z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <circle cx="769.878" cy="97.642" r="1.8" fill="#01a7e2"></circle>
      <circle cx="774.113" cy="89.437" r="1.8" fill="#01a7e2"></circle>
      <path
        fill="#01a7e2"
        d="M940.526 251.33a3.578 3.578 0 00-1.02-1.662 11.306 11.306 0 001.494-2.227c.094-.019.189-.038.283-.065a3.597 3.597 0 00.841-6.57 11.028 11.028 0 00-.764-2.806c.028-.007.055-.01.082-.019a3.6 3.6 0 10-4.224-5.009 11.313 11.313 0 00-2.102-1.102 3.6 3.6 0 10-6.753-.49l.004.01c-.146.035-.292.07-.437.112a11.27 11.27 0 00-2.499 1.042 6.747 6.747 0 10-5.66 10.742 11.237 11.237 0 003.044 6.783 3.6 3.6 0 105.824 3.265 11.186 11.186 0 004.954-.064l.003.011a3.6 3.6 0 106.93-1.952z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <circle cx="700.162" cy="73.181" r="1.8" fill="#01a7e2"></circle>
      <circle cx="690.958" cy="73.919" r="1.8" fill="#01a7e2"></circle>
      <path
        fill="#01a7e2"
        d="M867.726 239.883a3.578 3.578 0 00-.872 1.743 11.306 11.306 0 00-2.68-.092 3.619 3.619 0 00-.205-.206 3.597 3.597 0 00-6.023 2.755 11.028 11.028 0 00-1.98 2.13l-.059-.06a3.6 3.6 0 10-2.023 6.233 11.314 11.314 0 00.174 2.368 3.6 3.6 0 103.15 5.993l.007-.009c.107.105.214.21.325.312a11.27 11.27 0 002.205 1.572 6.747 6.747 0 1012.111-.867 11.237 11.237 0 004.153-6.166 3.6 3.6 0 10-.303-6.67 11.186 11.186 0 00-2.671-4.174l.008-.008a3.6 3.6 0 10-5.317-4.854z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path fill="#e6e6e6" d="M706.557 37H746.557V43H706.557z"></path>
      <path
        fill="#e6e6e6"
        d="M197.898 671.294c14.42 19.311 44.352 21.344 44.352 21.344s6.554-29.277-7.866-48.588-44.352-21.343-44.352-21.343-6.554 29.276 7.866 48.587z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M271.355 678.554c-9.515 12.743-29.267 14.084-29.267 14.084s-4.324-19.319 5.19-32.062 29.267-14.084 29.267-14.084 4.325 19.32-5.19 32.062z"
        transform="translate(-172.722 -161.051)"
      ></path>
      <path fill="#3f3d56" d="M0 575.657H519.557V577.898H0z"></path>
    </svg>
  );
};

export default ResearchIcon;
