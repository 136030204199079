import React from "react";

const Clipboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="7.5 7.5 50 50"
      id="clipboard-icon"
    >
      <path d="M51.313 8.291H13.688a.5.5 0 00-.5.5v47.418a.5.5 0 00.5.5h37.625a.5.5 0 00.5-.5V8.791a.5.5 0 00-.5-.5zm-.5 47.418H14.188V9.291h36.625v46.418z"></path>
      <path d="M17.038 19.943h6.614a.5.5 0 00.5-.5v-5.164l3.024-2.893a.5.5 0 00-.691-.723l-2.333 2.231v-.066a.5.5 0 00-.5-.5h-6.614a.5.5 0 00-.5.5v6.614a.5.5 0 00.5.501zm.5-6.614h5.614v.522l-2.241 2.144-1.458-1.458a.5.5 0 00-.707.707l1.804 1.804c.03.03.067.047.103.068.021.012.039.031.061.04a.5.5 0 00.376.002c.018-.007.033-.022.05-.032.038-.021.078-.039.11-.07l1.903-1.82v3.708h-5.614v-5.615zM27.689 17.194h20.273a.5.5 0 000-1H27.689a.5.5 0 000 1zM16.847 31.417a.506.506 0 00.191.039h6.614a.497.497 0 00.501-.501V24.34a.5.5 0 00-.501-.501h-6.614a.497.497 0 00-.501.501v6.615a.5.5 0 00.31.462zm4.205-3.77l2.1-2.101v4.201l-2.1-2.1zm1.393 2.808h-4.2l2.1-2.101 2.1 2.101zm-2.1-3.515l-2.1-2.101h4.2l-2.1 2.101zm-.707.707l-2.1 2.101v-4.201l2.1 2.1zM47.962 27.706H27.689a.5.5 0 000 1h20.273a.5.5 0 000-1zM17.038 42.965h6.614a.5.5 0 00.5-.5v-6.613a.5.5 0 00-.5-.5h-6.614a.5.5 0 00-.5.5v6.613a.5.5 0 00.5.5zm.5-6.613h5.614v5.613h-5.614v-5.613zM47.962 39.217H27.689a.5.5 0 000 1h20.273a.5.5 0 000-1zM17.038 54.476h6.614a.5.5 0 00.5-.5v-5.164l3.024-2.893a.5.5 0 00-.691-.723l-2.333 2.231v-.066a.5.5 0 00-.5-.5h-6.614a.5.5 0 00-.5.5v6.614a.5.5 0 00.5.501zm.5-6.615h5.614v.522l-2.241 2.144-1.458-1.458a.5.5 0 00-.707.707l1.804 1.804c.03.03.067.047.103.068.021.012.039.031.061.04a.5.5 0 00.376.002c.018-.007.033-.022.05-.032.038-.021.078-.039.11-.07l1.903-1.82v3.708h-5.614v-5.615zM47.962 50.727H27.689a.5.5 0 000 1h20.273a.5.5 0 000-1z"></path>
    </svg>
  );
};

export default Clipboard;
