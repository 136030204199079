import React from "react";

const PhoneIconClassic = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      id="phone-icon-classic"
    >
      <path d="M75.963 58.75a6.246 6.246 0 00-4.777-1.87 6.978 6.978 0 00-4.586 2.095c-5.177 5.186-5.218 5.23-5.265 5.282-.019.021-.031.045-.049.066-.015.018-.033.032-.048.05-1.06 1.376-3.27 3.462-4.647 3.568-3.793.294-9.614-3.069-15.539-8.993-6.51-6.51-9.7-12.728-9.487-15.505.098-1.273 2.411-3.537 4.041-4.682.028-.02.049-.045.075-.065.036-.028.075-.05.11-.081.056-.05.1-.09 5.235-5.215a6.48 6.48 0 00.223-9.363L28.173 10.961a6.748 6.748 0 00-8.913-.569 47.327 47.327 0 00-6.027 5.685c-6.11 6.235-4.17 17.022-3.976 18.01 2.73 18.029 19.808 34.28 21.137 35.52 1.24 1.329 17.52 18.435 35.545 21.14a31.37 31.37 0 004.61.32c3.992-.001 9.666-.712 13.283-4.214l.003-.002a47.433 47.433 0 005.771-6.11 6.745 6.745 0 00-.567-8.913zm-54.24-45.206a2.74 2.74 0 013.622.245L38.42 26.866a2.537 2.537 0 01-.222 3.705l-3.733 3.726a38270.33 38270.33 0 00-16.96-16.961 47.006 47.006 0 014.217-3.792zm44.833 73.25c-16.978-2.547-33.118-19.787-33.28-19.96a1.925 1.925 0 00-.109-.11c-.174-.161-17.415-16.302-19.961-33.28q-.008-.052-.019-.105c-.018-.086-1.558-7.853 1.706-12.958l16.494 16.495.078.078c-1.634 1.454-3.7 3.749-3.888 6.182-.421 5.48 5.472 13.466 10.647 18.64 6.938 6.938 13.564 10.554 18.674 10.153 2.367-.182 4.59-1.975 6.015-3.417l16.622 16.623c-4.875 2.903-12.872 1.675-12.98 1.66zm19.9-8.517a47.869 47.869 0 01-3.849 4.271c-1.902-1.905-6.384-6.388-16.958-16.957l3.78-3.789a3 3 0 011.948-.928 2.27 2.27 0 011.757.705L86.21 74.656a2.74 2.74 0 01.244 3.62z"></path>
    </svg>
  );
};

export default PhoneIconClassic;
