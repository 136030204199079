import React from "react";
import IV from "../../assets/iv.jpg";
import "./InfusionCenter.css";

const InfusionCenter = () => {
  return (
    <section className="subpage infusion-center">
      <article className="subpage-content">
        <header>
          <h1>INFUSION CENTER</h1>
        </header>
        <img src={IV} alt="IV bag" id="iv" />
        <p>
          At NKY Arthritis we administer medications for the treatment of
          autoimmune disease, osteoporosis, and immunological disorders. We are
          pleased to provide infusion or injection therapy to patients who are
          referred to us by their Ordering Provider. Simply just send us the
          order. Please see below for details of our infusion program.
        </p>
        <p>
          We are the only infusion center in Northern Kentucky with a Medical
          Doctor on site to manage any questions or complications.
        </p>
        <p>About our Infusion Referral Program</p>
        <ul>
          <li>
            Dr Callis is board certified in Internal Medicine and Rheumatology
            with greater than 10 years of experience managing an infusion
            center.{" "}
          </li>
          <li>
            Before the first infusion, she performs a pre-infusion assessment
            with the patient to review medical conditions that could impact care
            and to coordinate notes, labs, imaging, etc that is needed for the
            Prior Authorization
          </li>
          <li>
            Screening labs are ordered by Dr. Callis if not already completed
            with the infusion order. We have an onsite lab at the office for our
            patient’s convenience.
          </li>
          <li>
            We complete all Prior Authorizations, including writing letters and
            supplying documentation for appealing medication denials.
          </li>
          <li>
            If the patient misses an infusion appointment, we will let the
            Ordering Provider know and reschedule the patient as soon as
            possible.
          </li>
          <li>
            If the patient is ill, we will notify the Ordering Provider and hold
            the infusion medication when appropriate.
          </li>
          <li>
            If the patient has a complication of therapy, Dr Callis will
            evaluate and treat the patient. For example, oral / genital herpes,
            infusion reaction, oral thrush, etc. She will contact the Ordering
            Provider to notify them of the issue and coordinate a treatment
            plan.
          </li>
          <li>
            Dr. Callis takes call 24/7 for all of her patients including those
            in the Infusion Center. Infusion patients are made aware of this
            availability.
          </li>
          <li>
            The Ordering Provider will be faxed a Medication Administration
            Flowsheet after each infusion and our Flowsheets are posted to St.
            Elizabeth Epic under MD notes and Encounters.
          </li>
          <li>
            We track each patients’ labs to ensure they are completed as
            ordered.
          </li>
          <li>
            If your patient needs hearing tests or ocular examinations to
            monitor for medication side effect, we will coordinate these
            appointments for the patient.
          </li>
          <li>
            We track each patients’ follow up appointments with their Ordering
            Provider to ensure the desired clinical follow up is met.
          </li>
        </ul>
        <p>
          Medications we administer include but are not limited to the following
          drugs:
          <br />
          If you do not see your drug on the below list, please call our office
          to ask if we administer the medication. Our phone number is (859)
          282-1400.
        </p>
        <ul>
          <li>Cimzia</li>
          <li>Entyvio</li>
          <li>Evenity</li>
          <li>Ilumya</li>
          <li>Krystexxa</li>
          <li>Nucala</li>
          <li>Ocrevus</li>
          <li>Orencia</li>
          <li>Prolia</li>
          <li>Remicade</li>
          <li>Rituxan</li>
          <li>Ruxience</li>
          <li>Saphnelo</li>
          <li>Skyrizi</li>
          <li>Tepezza</li>
          <li>Tezspire</li>
          <li>Tysabri</li>
          <li>Trixima</li>
          <li>Uplizna</li>
          <li>Vygart</li>
          <li>Xolair</li>
        </ul>
      </article>
    </section>
  );
};

export default InfusionCenter;
