import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import UpArrowIcon from "../../assets/icons/UpArrowIcon";
import "./Medications.css";

const Medications = () => {
  // HashLink Scroll Offset
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -180;
    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: "smooth",
    });
  };

  // Arrow Behavior
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleOffset = () => {
      window.pageYOffset > 500 ? setScrolled(true) : setScrolled(false);
    };

    window.addEventListener("scroll", handleOffset);

    return () => window.removeEventListener("scroll", handleOffset);
  });
  return (
    <section className="subpage medications">
      <div className="subpage-content medications-content">
        <h1>MEDICATIONS</h1>
        <div className="mobile-layout">
          <div className="alphabet">
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#a"
            >
              A
            </HashLink>
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#b"
            >
              B
            </HashLink>
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#c"
            >
              C
            </HashLink>
            <span className="faded">D</span>
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#e"
            >
              E
            </HashLink>
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#f"
            >
              F
            </HashLink>
            <span className="faded">G</span>
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#h"
            >
              H
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#i"
            >
              I
            </HashLink>
            <span className="faded">J</span>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#k"
            >
              K
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#l"
            >
              L
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#m"
            >
              M
            </HashLink>
            <HashLink
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#n"
            >
              N
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#o"
            >
              O
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#p"
            >
              P
            </HashLink>
            <span className="faded">Q</span>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#r"
            >
              R
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#s"
            >
              S
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#t"
            >
              T
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#u"
            >
              U
            </HashLink>
            <span className="faded">V</span>
            <span className="faded">W</span>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#x"
            >
              X
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#y"
            >
              Y
            </HashLink>
            <HashLink
              smooth
              scroll={scrollWithOffset}
              onClick={(e) => e.preventDefault()}
              to="#z"
            >
              Z
            </HashLink>
          </div>
          <div className="medications-list">
            <div className="letter-container">
              <h2>A</h2>
              <ul className="letter-list" id="a">
                <li>
                  Abrilada{" "}
                  <ul>
                    <li>
                      <a
                        href="https://www.abrilada.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Actemra (Tocilizumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Tocilizumab-Actemra"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.actemra.com/?c=act-170f42a5caa&mkwid=-dc_pcrid_76897219627279_pkw_actemra_pmt_be&utm_source=bing&utm_medium=cpc&utm_campaign=BS%20-%20Branded%20RA%20DTC%20%7C%20Actemra%20%7C%20Patient%20%7C%20Branded%20%7C%20actemra.com%20%7C%20RA%20%7C%20act-170f42a5caa&utm_term=actemra&gclid=CNmz5qu8lO0CFUSHgQodUewDqQ&gclsrc=ds
                      "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Acthar (Repository corticotropin injection)
                  <ul>
                    <li>
                      <a
                        href="https://www.acthar.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Allopurinol
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Allopurinol-Zyloprim-Aloprim"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Amjevita
                  <ul>
                    <li>
                      <a
                        href="https://www.amjevita.com/supportplus/nurse-partner-program"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Arava (leflunomide)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Leflunomide-Arava"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Arcalyst
                  <ul>
                    <li>
                      <a
                        href="https://www.arcalyst.com
                      "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Avsola (Infliximab-axxq)
                  <ul>
                    <li>
                      <a
                        href="https://www.avsola.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>B</h2>
              <ul className="letter-list" id="b">
                <li>
                  Benlysta (Belimumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Belimumab-Benlysta"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.benlysta.com/?cc=ps_D3IIB5L9JK332594&mcm=60010&gclid=d44eaad76106131a6a96f56e132a39b7&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Bisphosphonates
                  <br /> (Fosamax / Alendronate, Boniva, Actonel, Zoledronic
                  Acid/ Reclast)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Bisphosphonate-Therapy
                      "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>C</h2>
              <ul className="letter-list" id="c">
                <li>
                  CellCept (Mychophenolate Mofetil)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Mycophenolate-Mofetil-Mycophenolate-Sodium"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.cellcept.com/?c=cel-16414af7653&&mkwid=-dc_pcrid_76897221779855_pkw_cellcept_pmt_be&utm_source=bing&utm_medium=cpc&utm_campaign=BS%20-%20Branded%20DTC%20%7C%20CellCept%20%7C%20Patient%20%7C%20Branded%20%7C%20Cellcept.com%20%7C%20Transplant%20%7C%20cel-16414af7653&utm_term=cellcept&gclid=c48d7ea9e29e18d5402d21109485cd52&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Cimzia (Certolizumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.cimzia.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Colcrys (Colchicine)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Colchicine-Colcrys-Mitigare"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Cosentyx (Secukinumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Secukinumab-Cosentyx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.cosentyx.com/?utm_source=Bing&utm_medium=paid&utm_campaign=Cosentyx.com_Branded_General_Bing_5.2020&utm_term=Brand_Exact%20%7c%20cosentyx&&utm_source=bing&utm_medium=cpc&utm_campaign=Cosentyx.com_Branded_General_Bing_5.2020;S;PH;BR;IMM;DTC;BR&utm_term=cosentyx&utm_content=Brand_Exact&gclid=COC3lOC7lO0CFQKogQodZboGeA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Cyltezo
                  <ul>
                    <li>
                      <a
                        href="https://patient.boehringer-ingelheim.com/us/products/cyltezo/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Cytoxan (Cyclophosphamide)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Cyclophosphamide-Cytoxan"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>E</h2>
              <ul className="letter-list" id="e">
                <li>
                  Enbrel (Entanercept)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.enbrel.com/how-enbrel-works?isipaid=true&&utm_source=bing&utm_medium=cpc&utm_campaign=BND_DTC_Decision_General%20Condition_Cross_EXM_2020&utm_term=enbrel&utm_content=About_Brand%20General&gclid=d061c7bf62af1a17519098061b65995e&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Entyvio (Vedolizumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.entyvio.com/sign-up?&utm_source=bing&utm_medium=cpc&utm_campaign=Brand-Entyvio-EX-RTG&utm_term=entyvio&utm_content=Entyvio&gclid=3cfc19a419db1b20b82d7a288fd4cbfd&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Evenity
                  <ul>
                    <li>
                      <a
                        href="https://www.evenity.com/?&utm_source=bing&utm_medium=cpc&utm_campaign=BNC_DTC_Decision_Competitor_BMM_2019&utm_term=%2Bforteo&utm_content=Forteo_BMM&gclid=a75ee21d9c0615b3002bbda4eb6baff3&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>F</h2>
              <ul className="letter-list" id="f">
                <li>
                  Forteo
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Teriparatide-Forteo"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.forteo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="letter-container">
              <h2>H</h2>
              <ul className="letter-list" id="h">
                <li>
                  Hadlima
                  <ul>
                    <li>
                      <a
                        href="https://www.hadlima.com/what-is-hadlima/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Hulio
                  <ul>
                    <li>
                      <a
                        href="https://www.hulio.com/meet-hulio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Humira (Adalimumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.humira.com/?cid=ppc_ppd_msft_franchise_brand_2015_humira_Exact_US-HUM-200038&&msclkid=43ce0f36ba381201eabe508fc039a6ba"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Hyrimoz
                  <ul>
                    <li>
                      <a
                        href="https://cloud.engage.sandoz.com/usa_hyrimoz/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>I</h2>
              <ul className="letter-list" id="i">
                <li>
                  Imuran (Azathioprine)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Azathioprine-Imuran"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Ilaris (Canakinumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Canakinumab-Ilaris"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Inflectra (Infliximab-dyyb)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://inflectra.pfizerpro.com/?source=bing&HBX_PK=s_inflectra%20website&skwid=43700056975893111"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  IVIG
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Intravenous-Immunoglobulin-IVIG"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>K</h2>
              <ul className="letter-list" id="k">
                <li>
                  Kevzara (Sarilumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Sarilumab-Kevzara"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.kevzara.com/?utm_source=bing&utm_medium=cpc&utm_campaign=Bing_Kevzara+DTC_Brand_Core_Exact&utm_term=kevzara&msclkid=6f5beac4cd17173ee336b57dbbc08c94&gclid=CIe-77m7lO0CFcpLgQodKT4BdQ&gclsrc=ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Kineret (Anakinra)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Anakinra-Kineret"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.kineretrx.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Krystexxa (Pegloticase)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Pegloticase-Krystexxa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.krystexxa.com/support/?utm_source=bing&utm_medium=cpc&utm_campaign=Branded+-+General&&msclkid=b3d98c7c4c3f151b14bed3c53a7099dd&utm_source=bing&utm_medium=cpc&utm_campaign=Branded%20-%20General&utm_term=krystexxa&utm_content=General%20-%20About%20-%20Exact&gclid=b3d98c7c4c3f151b14bed3c53a7099dd&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>L</h2>
              <ul className="letter-list" id="l">
                <li>
                  Lupkynis
                  <ul>
                    <li>
                      <a
                        href="https://www.lupkynis.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Arthritis Foundation Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>M</h2>
              <ul className="letter-list" id="m">
                <li>
                  Methotrexate (Rheumatrex, Trexall, Oxtrexup, Rasuvo)
                  <ul>
                    <li>
                      <a
                        href="https://www.arthritis.org/drug-guide/medication-topics/understanding-methotrexate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Arthritis Foundation Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Minocycline
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Minocycline-Minocin"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Mitigare
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Colchicine-Colcrys-Mitigare"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.mitigare.com/mitigare-true-blue-savings-card/?msclkid=aac5076929bc18b2264e58d05abbb556&utm_source=bing&utm_medium=cpc&utm_campaign=*Brand%20-%20Desktop&utm_term=colcrys&utm_content=Colcrys"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Myfortic (Mycophenolate Sodium)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Mycophenolate-Mofetil-Mycophenolate-Sodium"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>N</h2>
              <ul className="letter-list" id="n">
                <li>
                  Neoral (Cyclosporin)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Cyclosporine-Neoral-Sandimmune-Gengraf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  NSAIDs (Non Steroid Anti Inflammatory Drugs)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/NSAIDs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>O</h2>
              <ul className="letter-list" id="o">
                <li>
                  Ocrevus
                  <ul>
                    <li>
                      <a
                        href="https://www.ocrevus.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Ofev
                  <ul>
                    <li>
                      <a
                        href="https://www.ofev.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Olumiant (Baricitinib)
                  <ul>
                    <li>
                      <a
                        href="https://www.olumiant.com/what-is-olumiant?utm_id=bi_cmp-291700382_adg-1275433935721278_ad-79714719811962_kwd-79714914522104:loc-71155_dev-c_ext-&msclkid=1a4cbe5a2df91021b41ce1e3556f363e&utm_source=bing&utm_medium=cpc&utm_campaign=US_DTC_Olumiant_Brand_Exact&utm_term=olumiant&utm_content=Brand_Olumiant"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Omvoh
                  <ul>
                    <li>
                      <a
                        href="https://omvoh.lilly.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Orencia
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Abatacept-Orencia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.orencia.com/?cid=sem_695662&gclid=652703cb223b10e0ccd55c725362ab3f&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Otezla (Apremilast)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Apremilast-Otezla"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.otezla.com/?utm_source=bing&utm_medium=cpc&utm_term=otezla&utm_content=otezla&utm_campaign=PsO%20Otezla%20Branded%20General_PH&msclkid=581d2488548c194bc5007b31256152ab"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>

              <h2>P</h2>
              <ul className="letter-list" id="p">
                <li>
                  Plaquenil (Hydroxychloroquine)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Hydroxychloroquine-Plaquenil"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Prednisone
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Prednisone-Deltasone"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Prolia (Denosumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Denosumab-Prolia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.prolia.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="letter-container">
              <h2>R</h2>
              <ul className="letter-list" id="r">
                <li>
                  Rayos
                  <ul>
                    <li>
                      <a
                        href="https://www.rayosrx.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Reclast
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/Learning-Center/Medication-Guides/Medication-Guide-Zoledronic-Acid-Reclast"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Remicade (Infliximab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.remicade.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Renflexis (Infliximab-abda)
                  <ul>
                    <li>
                      <a
                        href="https://www.renflexis.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Riabni
                  <ul>
                    <li>
                      <a
                        href="https://www.riabni.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>

                <li>
                  Rinvoq (Upadacitinib)
                  <ul>
                    <li>
                      <a
                        href="https://www.rinvoq.com/about-rinvoq/rinvoq-results?cid=ppc_ppd_msft_branded_competitive_olumiant_exact_usrnqr190596&cid=ppc_ppd_msft_branded_competitive_olumiant_exact_usrnqr190596&&msclkid=bb8716929e9017a33249492be6ed05eb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Rituxan (Rituximab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Rituximab-Rituxan-MabThera"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.rituxan.com/gpa-mpa.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer - ANCA Vasculitis
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.rituxan.com/pv.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer - Pemphigus
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.rituxan.com/ra.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer - Rheumatoid Arthirits
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Ruxience (Rituximab - pvvr)
                  <ul>
                    <li>
                      <a
                        href="https://www.ruxience.com/about-biosimilars?gclid=CjwKCAiAhKycBhAQEiwAgf19egwUfIRZTlPjaFiX22uWnZkY2sLl-b8ylWHkvLYYzhreMRbGByYRBhoC-pkQAvD_BwE&gclsrc=aw.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="letter-container">
              <h2>S</h2>
              <ul className="letter-list" id="s">
                <li>
                  Saphnelo
                  <ul>
                    <li>
                      <a
                        href="https://www.saphnelo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Siliq (Brodalumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.siliq.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Simponi (Golimumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.simponi.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Simponi Aria (Golimumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/TNF-Inhibitors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.simponi.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Skyrizi (Risankizumab-rzaa)
                  <ul>
                    <li>
                      <a
                        href="https://www.skyrizi.com/?cid=ppc_ppd_msft_skyrizi_psoriasis_branded_skyrizi_Exact_US-SKZD-190272&&msclkid=589f1a6896f213fff8f745d1eff5b9a2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Spevigo
                  <ul>
                    <li>
                      <a
                        href="https://patient.boehringer-ingelheim.com/us/products/spevigo/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Stelara (Ustekinumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Ustekinumab-Stelara"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.stelarainfo.com/crohns-disease/about-stelara#how-stelara-works&msclkid=c39c49d866841c97441c16611ec671f4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Sulfasalazine (Azulfidine)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Sulfasalazine-Azulfidine"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="letter-container">
              <h2>T</h2>
              <ul className="letter-list" id="t">
                <li>
                  Tavneos
                  <ul>
                    <li>
                      <a
                        href="https://www.tavneos.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Tepezza
                  <ul>
                    <li>
                      <a
                        href="https://www.tepezza.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Tremfya (Guselkumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.tremfya.com/?utm_source=bing&utm_medium=cpc&utm_campaign=BI-USA-ENG-PS-Tremfya-BC-EX-RN-DTC_Core&utm_content=Core&utm_term=tremfya&&msclkid=8f22cfa8720e1ce1337290110a5e0c40&gclid=8f22cfa8720e1ce1337290110a5e0c40&gclsrc=3p.ds"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Tymlos (Abaloparatide)
                  <ul>
                    <li>
                      <a
                        href="https://www.tymlos.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Taltz (Ixekizumab)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Ixekizumab-Taltz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.taltz.com/?utm_id=bi_cmp-291699326_adg-1266637835370854_ad-79164937913428_kwd-79165155257145:loc-190_dev-c_ext-&utm_source=bing&utm_medium=ppc&campaign=291699326&adgroup=1266637835370854&ad=79164937913428&utm_keyword=kwd-79165155257145:loc-190&msclkid=04d80428504a19069c755b912533d7dc&utm_campaign=US_DTC_Taltz_Brand_Core_Exact&utm_term=taltz&utm_content=Brand%20Only_Taltz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>U</h2>
              <ul className="letter-list" id="u">
                <li>
                  Uloric (febuxostat)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Febuxostat-Uloric"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>Y</h2>
              <ul className="letter-list" id="y">
                <li>
                  Yusimry
                  <ul>
                    <li>
                      <a
                        href="https://investors.coherus.com/news-releases/news-release-details/coherus-launches-yusimrytm-biosimilar-humirar-995-carton-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>X</h2>
              <ul className="letter-list" id="x">
                <li>
                  Xeljanz (Tofacitinib)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Tofacitinib-Citrate-Xeljanz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.xeljanz.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Drug Manufacturer
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className="letter-container">
              <h2>Z</h2>
              <ul className="letter-list" id="z">
                <li>
                  Zurampic (Lesinurad)
                  <ul>
                    <li>
                      <a
                        href="https://www.rheumatology.org/I-Am-A/Patient-Caregiver/Treatments/Lesinurad-Zurampic"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ACR Information
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={scrolled ? "arrow-wrapper-scrolled" : "arrow-wrapper"}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <UpArrowIcon />
        </div>
      </div>
    </section>
  );
};

export default Medications;
