import React from "react";
import "./Footer.css";

const Footer = () => {
  const dateYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <p>
        <a href="/nky-arthritis-hipaa-notice.pdf">HIPAA Notice</a> |{" "}
        <a href="/nky-arthritis-nondiscrimination-notice.pdf">
          Nondiscrimination Notice
        </a>
      </p>
      <p>
        &copy; Northern Kentucky Arthritis Rheumatology and Infusion LLC{" "}
        {dateYear}
      </p>
    </footer>
  );
};

export default Footer;
