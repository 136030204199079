import React from "react";
import DrCallis from "../../assets/dr-callis-headshot-big.jpg";
import "./AboutDrCallis.css";

const AboutDrCallis = () => {
  return (
    <section className="subpage about-dr-callis">
      <article className="subpage-content">
        <header>
          <h1>MEET YOUR RHEUMATOLOGIST</h1>
        </header>
        <h2>Dr. Rebecca Callis</h2>
        <img src={DrCallis} alt="Doctor Callis" id="dr-callis" />
        <p>
          Dr. Rebecca Brinker Callis is originally from Northern Kentucky. She
          graduated college summa cum laude and Phi Beta Kappa from the
          University of Kentucky. While there as an undergraduate student, she
          created the Student Volunteer Center and served as an ambassador for
          the College of Arts and Sciences. Her medical education was completed
          at the University of Kentucky College of Medicine where she was
          promoted every year with High Distinction and graduated with Alpha
          Omega Alpha honors. She then completed her Internal Medicine Residency
          and Rheumatology Fellowship at Washington University in St. Louis, MO
          in 2011. She is board certified in Internal Medicine and Rheumatology.
          While in Rheumatology Fellowship, she co-authored 5 chapters of the
          Washington Manual of Rheumatology Subspecialty Consult textbook and
          was recognized by the governor as an outstanding physician at the St.
          Louis Veterans Administration Hospital.
        </p>
        <p>
          After fellowship, Dr. Callis moved to the Pacific Northwest where she
          has practiced for 9 years. She has special interest in treating
          rheumatoid arthritis, psoriatic arthritis, ankylosing spondylitis and
          seronegative spondyloarthropathies. She has great experience and
          interest in the rare extra articular manifestations of these diseases
          and other disease such as ocular, cardiac, lung, gut, blood vessel,
          and skin involvement. Dr. Callis is a multiple recipient of the
          patient elected “My Doc Rocks” award from Arthritis Today magazine.
          She believes that educating patients about their disease and their
          medications promotes better clinical outcomes and fosters a
          collaborative relationship with the patient to make medical decisions
          together.
        </p>
        <p>
          Dr. Callis moved back home to Northern Kentucky in 2019 to be closer
          to family. In her free time, she enjoys various arts & crafts (as you
          can see by her exam rooms), watching college basketball (Go UK!),
          gardening, and is an avid animal lover (3 cats and a dog).
        </p>
      </article>
    </section>
  );
};

export default AboutDrCallis;
