import React from "react";
import { Header, Footer } from "./components/index";

const Layout = (props) => {
  return (
    <>
      <Header />
      <main id="main">{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;
