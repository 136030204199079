import React from "react";

const UpArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      id="up-arrow-icon"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        fill="#f39820"
        enableBackground="new 0 0 100 100"
        transform="rotate(-90 50 34.5) scale(.69)"
        viewBox="0 0 100 100"
      >
        <path d="M50 2.5C23.8 2.5 2.5 23.8 2.5 50S23.8 97.5 50 97.5 97.5 76.2 97.5 50 76.2 2.5 50 2.5zm25.6 51.2L59.7 69.6c-1 1-2.3 1.5-3.7 1.5s-2.7-.5-3.7-1.5c-2-2-2-5.3 0-7.4l7-7H28c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2h31.4l-7-7c-2-2-2-5.3 0-7.4 2-2 5.3-2 7.4 0l15.9 15.9c2 2.1 2 5.3-.1 7.4z"></path>
      </svg>
    </svg>
  );
};

export default UpArrowIcon;
