import React from "react";

const Computer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#f39820"
      viewBox="0 0 100 100"
      id="computer"
    >
      <path d="M87.5 11.5h-75a1 1 0 00-1 1V70a1 1 0 001 1H44v3.764l-2.322 4.644-2.092 2.092H32.5a1 1 0 00-.698.284l-5.125 5a1 1 0 00.698 1.716H72.5c.407 0 .775-.248.929-.625.289-.71.289-.71-5.23-6.091a1.002 1.002 0 00-.699-.284h-7.086l-2.092-2.092L56 74.764V71h31.5a1 1 0 001-1V12.5a1 1 0 00-1-1zm-74 2h73v48h-73v-48zM55.882 79H44.118l1.5-3h8.764l1.5 3zM60 83.5h7.093a498.446 498.446 0 013.05 3H29.832l3.075-3H40a1 1 0 00.707-.293L42.914 81h14.172l2.207 2.207A1 1 0 0060 83.5zM54 74h-8v-3h8v3zm32.5-5h-73v-5.5h73V69z"></path>
      <path d="M41.446 34.508A5.982 5.982 0 0043.5 30c0-3.309-2.691-6-6-6s-6 2.691-6 6c0 1.798.798 3.409 2.055 4.51C27.507 36.131 24 41.66 24 50a1 1 0 001 1h25.02a1 1 0 00.979-1.204c-.056-8.226-3.554-13.678-9.553-15.288zM33.5 30c0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4-4-1.794-4-4zm-7.481 19c.197-5.272 2.09-13 11.481-13s11.284 7.728 11.481 13H26.02zM75 24H55a1 1 0 100 2h20a1 1 0 100-2zM75 29H55a1 1 0 100 2h20a1 1 0 100-2zM75 34H55a1 1 0 100 2h20a1 1 0 100-2zM75 39H55a1 1 0 100 2h20a1 1 0 100-2zM75 44H55a1 1 0 100 2h20a1 1 0 100-2zM75 49H55a1 1 0 100 2h20a1 1 0 100-2z"></path>
    </svg>
  );
};

export default Computer;
