import React from "react";
import CallingIcon from "../../../assets/icons/fancy/CallingIcon";
import "./HomeWelcome.css";

const HomeWelcome = () => {
  return (
    <section className="home-welcome">
      <article className="welcome-article">
        <h2 className="welcome-title">Don't let your arthritis stop you</h2>
        <p>
          Dr. Callis and her staff are dedicated to diagnosing and treating
          autoimmune diseases. We all work together to help patients understand
          their disease, treatment options, and medication administration. Let
          us help you take the stress out of your journey to start feeling
          better.
        </p>
      </article>
      <div className="welcome-appt-container">
        <div className="welcome-appt">
          <CallingIcon />
          <a href="tel:8592821400">
            <div className="schedule">(859) 282-1400</div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HomeWelcome;
