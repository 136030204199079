import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.development";
import PaymentButton from "../PaymentButton/PaymentButton";
import Logo from "../../assets/logos/nky-arthritis-logo-name.jpg";
import "./Header.css";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  const headerScrollOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: "smooth",
    });
  };

  return (
    <>
      <header className="header">
        <div className="desktop-container">
          <div className="contact-list">
            <span className="phone">
              <a href="tel:8592821400">(859) 282-1400</a>
            </span>
            <address className="address">
              <a
                href="https://goo.gl/maps/K3jdzNi4GyJVKEwy9"
                target="_blank"
                rel="noopener noreferrer"
              >
                6909 Burlington Pike Suite A Florence, KY 41042
              </a>
            </address>
            <Link to="/" className="logo-link">
              <img src={Logo} alt="Logo" className="mobile-logo" />
            </Link>
            <div className="portal-pay">
              <li className="portal">
                <a
                  href="https://nkyarthritis.prognocis.com/prognocis/nkyarthritisClinicIndex.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Patient Portal
                </a>
              </li>
              <li className="pay">
                <PaymentButton />
              </li>
            </div>
          </div>
          <div className="logo-nav">
            <Link to="/" id="home-logo-link">
              <img src={Logo} alt="Logo" id="nav-logo" />
            </Link>
            <div className="header-text-container">
              <ul className="nav-list">
                <li>
                  <NavLink
                    to="/about-dr-callis"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    About Dr. Callis
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/what-is-rheumatology"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    What is Rheumatology
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/philosophy-of-practice"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    Our Philosophy
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/infusion-center"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    Infusion Center
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/what-to-expect"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    What to Expect
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/disease-information"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    Disease Information
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/medications"
                    activeStyle={{ color: "var(--light-blue" }}
                  >
                    Medications
                  </NavLink>
                </li>
                <li>
                  <HashLink smooth to="/#contact">
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div className="navbar-mobile-container">
        <span
          className="navbar-mobile-title"
          onClick={() => setNavOpen(!navOpen)}
        >
          MENU
        </span>
        <ul
          id="navbar-mobile"
          onClick={() => setNavOpen(false)}
          className={navOpen ? "nav-visible" : "nav-hidden"}
        >
          <li>
            <NavLink exact={true} to="/" activeClassName="nav-active">
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink to="/about-dr-callis" activeClassName="nav-active">
              ABOUT DR. CALLIS
            </NavLink>
          </li>
          <li>
            <NavLink to="/what-is-rheumatology" activeClassName="nav-active">
              WHAT IS RHEUMATOLOGY
            </NavLink>
          </li>
          <li>
            <NavLink to="/philosophy-of-practice" activeClassName="nav-active">
              PHILOSOPHY OF PRACTICE
            </NavLink>
          </li>
          <li>
            <NavLink to="/infusion-center" activeClassName="nav-active">
              INFUSION CENTER
            </NavLink>
          </li>
          <li>
            <NavLink to="/what-to-expect" activeClassName="nav-active">
              WHAT TO EXPECT
            </NavLink>
          </li>
          <li>
            <NavLink to="/disease-information" activeClassName="nav-active">
              DISEASE INFORMATION
            </NavLink>
          </li>
          <li>
            <NavLink to="/medications" activeClassName="nav-active">
              MEDICATIONS
            </NavLink>
          </li>
          <li>
            <HashLink smooth scroll={headerScrollOffset} to="/#contact">
              CONTACT
            </HashLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
