import React from "react";

const BloodIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      x="0"
      y="0"
      fill="#1A1A1A"
      viewBox="0 0 100 100"
      id="blood-icon"
    >
      <path
        style={{
          WebkitTextIndent: "0",
          textIndent: "0",
          WebkitTextTransform: "none",
          textTransform: "none",
          blockProgression: "tb",
        }}
        d="M50 958.362c-.694 0-1.474.483-1.781 1.063-5.91 11.153-12.594 20.536-17.907 29.28C25 997.452 21 1005.628 21 1014.363c0 17.23 12.858 32 29 32s29-14.77 29-32c0-8.735-4-16.911-9.313-25.656-5.312-8.745-11.998-18.128-17.906-29.281A2 2 0 0050 958.362zm0 5.938c5.594 10.025 11.58 18.73 16.281 26.468 5.194 8.55 8.719 16.092 8.719 23.594 0 15.245-11.319 28-25 28s-25-12.755-25-28c0-7.502 3.525-15.044 8.719-23.594 4.7-7.738 10.687-16.443 16.281-26.468zm-16.125 54.03c-1.174.073-2.109 1.363-1.813 2.5 2.172 8.686 8.938 15.532 17.938 15.532 1.057.015 2.031-.943 2.031-2s-.974-2.015-2.031-2c-7 0-12.234-5.185-14.063-12.5-.216-.904-1.134-1.586-2.062-1.531z"
        color="#000"
        enableBackground="accumulate"
        overflow="visible"
        transform="translate(0 -952.362)"
      ></path>
    </svg>
  );
};

export default BloodIcon;
