import React from "react";
import Laughing from "../../assets/laughing-sepia.jpg";
import "./WhatToExpect.css";

const WhatToExpect = () => {
  return (
    <section className="subpage what-to-expect">
      <article className="subpage-content">
        <header>
          <h1>WHAT TO EXPECT</h1>
        </header>
        <img src={Laughing} alt="Man laughing" id="laughing" />
        <p>
          Autoimmune diseases are complex and different manifestations may
          present over time, so your visit will include a very detailed medical
          history as well as a complete detailed review of all bodily systems.
          Sometimes these conditions are inherited so a detailed family history
          is also obtained. Expect to receive a detailed joint exam of all
          joints, not just the painful ones. This may help the doctor diagnose
          the type of arthritis. The doctor may order blood work to evaluate for
          inflammation and X-rays to look for damage to the joints or
          surrounding structures. Sometimes further joint imaging may be
          warranted depending on the results.
        </p>
      </article>
    </section>
  );
};

export default WhatToExpect;
