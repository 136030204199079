import React from "react";
import CoupleGaze from "../../assets/couple-gaze.jpg";
import "./WhatIsRheumatology.css";

const WhatIsRheumatology = () => {
  return (
    <section className="subpage what-is-rheumatology">
      <article className="subpage-content">
        <header>
          <h1>WHAT IS RHEUMATOLOGY?</h1>
        </header>
        <img src={CoupleGaze} alt="Couple holding hands" id="couple-gaze" />
        <p>
          In Rheumatology, we treat non-surgical musculoskeletal conditions
          (such as gout and pseudogout) as well as conditions that result from
          the inappropriate activations of a patient's immune system against
          themselves (such as Rheumatoid Arthritis, Psoriatic Arthritis,
          Ankylosing Spondylitis, Sjogren’s Syndrome, Ulcerative Colitis and
          Crohn’s related arthritis, Lupus, Scleroderma, etc). This incorrect
          activation of the immune system against one’s self can manifest in
          many locations including the joints, skin, eye, gut, lungs, etc. In
          the joints, these diseases usually present with joint swelling, pain,
          and stiffness. If ignored these diseases may permanently damage the
          joints. As rheumatologist, I prescribe medications to decrease this
          incorrect immune activation and therefore resolve the pain and
          debility.
        </p>
      </article>
    </section>
  );
};

export default WhatIsRheumatology;
