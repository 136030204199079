import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./Layout";
import {
  Home,
  WhatIsRheumatology,
  PhilosophyOfPractice,
  WhatToExpect,
  DiseaseInformation,
  AboutDrCallis,
  Medications,
  InfusionCenter,
} from "./components/index";
import "./css/style.css";

class App extends Component {
  state = {};
  render() {
    return (
      <div id="app" style={{ margin: "auto" }}>
        <Layout>
          <Switch>
            <Route
              path="/philosophy-of-practice"
              component={PhilosophyOfPractice}
            />
            <Route path="/medications" component={Medications} />
            <Route path="/infusion-center" component={InfusionCenter} />
            <Route
              path="/what-is-rheumatology"
              component={WhatIsRheumatology}
            />
            <Route path="/what-to-expect" component={WhatToExpect} />
            <Route path="/disease-information" component={DiseaseInformation} />
            <Route path="/about-dr-callis" component={AboutDrCallis} />
            <Route path="/" component={Home} />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default App;
