import React from "react";
import HomeWelcome from "./HomeWelcome/HomeWelcome";
import HomeServices from "./HomeServices/HomeServices";
import HomeOfficePhotos from "./HomeOfficePhotos/HomeOfficePhotos";
import HomeDrCallis from "./HomeDrCallis/HomeDrCallis";
import HomeQuest from "./HomeQuest/HomeQuest";
import HomeForms from "./HomeForms/HomeForms";
import HomeContact from "./HomeContact/HomeContact";
import Workspace from "./Workspace/Workspace";

const Home = () => {
  return (
    <section>
      <Workspace />
      <HomeWelcome />
      <HomeServices />
      <HomeForms />
      <HomeDrCallis />
      <HomeQuest />
      <HomeOfficePhotos />
      <HomeContact />
    </section>
  );
};

export default Home;
