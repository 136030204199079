import React, { Component } from "react";

class PaymentButton extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://www.convergepay.com/hosted-payments/buy_button_script/715a455967427034534f6d38456b4e6566492f4f527741414159504f62623257";
    this.div.appendChild(script);
  }
  render() {
    return (
      <div ref={(el) => (this.div = el)}>
        {" "}
        <button className="payment-button">Online Payments</button>
      </div>
    );
  }
}

export default PaymentButton;
