import React, { Component } from "react";
import "./Workspace.css";

class Workspace extends Component {
  state = {};

  componentDidMount() {
    this.startShow = setInterval(this.changeSlide, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.startShow);
  }

  render() {
    let currentSlide = 0;

    this.changeSlide = () => {
      const slides = document.querySelectorAll(".slide");
      slides[currentSlide].classList = "slide";
      currentSlide = (currentSlide + 1) % slides.length;
      slides[currentSlide].classList = "slide showing";
    };
    return (
      <section className="workspace">
        <div className="banner-title-wrapper">
          <h2 className="pre-title">Get Control of your</h2>
          <h2 className="slide showing" id="joint-pain">
            Joint Pain
          </h2>
          <h2 className="slide">Rheumatoid Arthritis</h2>
          <h2 className="slide">Psoriatic Arthritis</h2>
          <h2 className="slide">Ankylosing Spondylitis</h2>
          <h2 className="slide">Crystalline Arthritis</h2>
          <h2 className="slide" id="articular">
            Extra Articular
            <br /> Rheumatic Diseases
          </h2>
        </div>
      </section>
    );
  }
}

export default Workspace;
