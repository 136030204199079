import React from "react";
import ExamRoomWall from "../../../assets/office-photos/exam-room-wall.jpg";
import ExamRoom from "../../../assets/office-photos/exam-room.jpg";
import InfusionRoom from "../../../assets/office-photos/infusion-chairs-wide.jpg";
import InfusionChairs2 from "../../../assets/office-photos/infusion-chairs-2.jpg";
import Hallway from "../../../assets/office-photos/hallway-edit.jpg";
import Lobby from "../../../assets/office-photos/lobby-2.jpg";
import ExteriorLogo from "../../../assets/office-photos/exterior-logo.jpg";

import "./HomeOfficePhotos.css";

const HomeOfficePhotos = () => {
  return (
    <section className="home-office-photos">
      <div className="photos-container">
        <img src={ExteriorLogo} alt="Entrance" id="exterior-logo" />
        <img src={Lobby} alt="Lobby" id="lobby" />
        <img src={Hallway} alt="Hallway" id="hallway" />
        <img src={InfusionRoom} alt="Infusion Room" id="infusion-room" />
        <img
          src={InfusionChairs2}
          alt="Infusion Room Chairs"
          id="infusion-chairs-2"
        />
        <img src={ExamRoom} alt="Exam room" id="exam-room" />
        <img src={ExamRoomWall} alt="Colorful art" id="exam-room-wall" />
      </div>
    </section>
  );
};

export default HomeOfficePhotos;
