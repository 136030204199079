import React from "react";
import PhoneIcon from "../../../assets/icons/PhoneIconClassic";
import FaxIcon from "../../../assets/icons/FaxIcon";
import "./HomeContact.css";

const HomeMap = () => {
  return (
    <section className="home-contact" id="contact">
      <h2 className="contact-title">Contact</h2>
      <div className="contact-hours">
        <h3 className="hours-title">Hours</h3>
        <ul className="days-list">
          <li>Monday</li>
          <li>Tuesday</li>
          <li>Wednesday</li>
          <li>Thursday</li>
          <li>Friday</li>
        </ul>
        <ul className="time-list">
          <li>7:30 AM - 5:00PM</li>
          <li>7:30 AM - 5:00PM</li>
          <li className="closed">Closed</li>
          <li>7:30 AM - 5:00PM</li>
          <li>7:30 AM - 5:00PM</li>
        </ul>
      </div>
      <div className="contact-phone-address">
        <h3>Phone</h3>
        <p className="contact-phone">
          <PhoneIcon />
          <a href="tel:8592821400">(859) 282-1400</a>
        </p>
        <p className="contact-fax">
          <FaxIcon />
          (859) 282-9200
        </p>
        <h3 className="location-title">Address</h3>
        <p className="location-address">
          <a
            href="https://goo.gl/maps/K3jdzNi4GyJVKEwy9"
            target="_blank"
            rel="noopener noreferrer"
          >
            6909 Burlington Pike
            <br />
            Suite A <br />
            Florence, KY 41042
          </a>
        </p>
      </div>
      <div className="contact-map-wrapper">
        <iframe
          id="home-map"
          title="google-map"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJleNVrKrHQYgRHpr2_pBlEx8&key=AIzaSyA1sTnuxXH0JCeYEMnbMhvSM7_ZZilLypY&zoom=14"
        ></iframe>
      </div>
    </section>
  );
};

export default HomeMap;
