import React from "react";
import ResearchIcon from "../../../assets/icons/fancy/ResearchIcon";
import "./HomeQuest.css";

const HomeQuest = () => {
  return (
    <section className="home-quest">
      <div className="quest-text">
        <h2 className="quest-title">Quest Lab</h2>
        <p className="quest-description">
          Mon, Tues, Thurs, Fri 8-3:30 pm.
          <br />
          <br />
          Closed 12:30-1pm for lunch.
        </p>
      </div>
      <div className="quest-img-wrapper">
        <ResearchIcon />
      </div>
    </section>
  );
};

export default HomeQuest;
