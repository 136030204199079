import React from "react";
import "./HomeForms.css";

const HomeForms = () => {
  return (
    <section className="home-forms-section">
      <div className="home-forms">
        <div className="forms-title">
          <h2>Relief begins here.</h2>
          <h2>Get a head start on your visit.</h2>
        </div>
        <div className="home-forms-btn-container">
          <div className="home-forms-btn">
            <a
              href="/nky-arthritis-roi.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Release of Information Form
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeForms;
